.rectangle {
  width: 120%;
  height: 60%;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 10px; */
  position: fixed;
  top: 47%;
  align-self: center;
  left: 50%;
  /* right: 40%; */
  transform: translate(-50%, -50%);
}

.left-section {
  /* flex: 1; */
  height: 85%;
  width: 33%;
  background: var(--bgCarousel);
  opacity: var(--CarouselOpacity);
  box-shadow: -50px 0px 28px -30px #00000066 !important;
  backdrop-filter: blur(2px);

  color: var(--CarouselFontColor);
  font-family: var(--CarouselFontFamily);
  font-weight: var(--CarouselFontWeight);
  text-align: left;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  /* margin-left: 20%; */
}

.right-section {
  /* flex: 1; */
  width: 120%;
  height: 120%;
  position: relative;
  left: -0.5%;
  border-radius: 1%;
  animation: slideOpen ease .5;
}

@keyframes slideOpen {
  0% {opacity:0; scaleX: 0 ; transform: translateX(-100%);};
  10% {opacity: .05; scaleX: 0 ; transform: translateX(-100%)};
  50% {opacity: .1; scaleX: .75 ; transform: translateX(-75%)};    
  100% {opacity: 1; scaleX: 1 ; transform: translateX(0%)}
}

.close-button {
  position: absolute;
  top: 7%;
  right: 5%;
  font-size: 1.2vw;
  cursor: pointer;
}

.heading {
  position: absolute;
  font-size: var(--CarouselFontSize);
  font-family: var(--CarouselFontFamily);
  font-weight: var(--CarouselFontWeight);
  /* margin: 0; */
  left: 8%;
  top: 3%;
  color: var(--CarouselFontColor);
  padding-right: 8%;
  animation: fadeIn ease 0.2s;
	filter: brightness(150%);
  -webkit-animation: fadeIn ease 0.2s;
}

.divider {
  position: absolute;
  width: 15%;
  height: 0.5%;
  font-size: 0.104vw;
  background-color: var(--bgSeparator);
  border: none;
  left: 8%;
  top: 17%;
}

.content {
  position: absolute;
  left: 8%;
  font-family: var(--CarouselContentFontFamily);
  font-weight: var(--CarouselContentFontWeight);
  font-size: var(--CarouselContentFontSize);
	filter: brightness(150%);
  top: 21%;
  width: 80%;
  animation: fadeIn ease 0.3s;
  -webkit-animation: fadeIn ease 0.3s;
  line-height: var(--CarouselContentLineHeight);
  font-style: var(--CarouselContentFontStyle);
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.image {
  max-width: 90%;
  max-height: 90%;
}

.left-image-container {
  position: absolute;
  bottom: 0;
  margin-left: 8%;
  padding-left: 0;
  width: 100%;
  height: 50%;
}

.left-image {
  bottom: 25%;
  position: absolute;
  border: 10px solid var(--fontWhite);
  border-radius: 5px;
	/* width: 30% !important; */
	width: calc(2vw + 4svh) !important;
	height: auto !important;
}

.LeftArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5%;
  margin-left: 9%;
}
.RightArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.1%;
  margin-right: 9%;
}

.scan-content{
  position: absolute;
	width: 80%;
	bottom: 0;
}
.scan-content a{
	/* text-decoration: none; */
	font-size: var(--DataCardCTAFontSize);
  color: var(--fontToolbar);

}

.cross-btn{
  position: absolute;
  top: 10%;
  cursor: pointer;
}

.animationMenu-enter {
  opacity: 0;
}

.animationMenu-enter-active {
  opacity: 1;
  /* transition: opacity 150ms ease-out; */
	animation: animateGrow ease-out 0.225s;
}

.animationMenu-exit {
	opacity: 1;
}

.animationMenu-exit-active {
	opacity: 0;
  /* transition: opacity 150ms ease-in; */
	animation: animateExit ease-out 0.225s;
}

@keyframes animateGrow {
	0% {opacity:0; scale:0%;};
	25% {opacity: var(--HotspotHoverOpacity); scale:0%}
	100% {opacity: var(--HotspotHoverOpacity); scale:100%}
}

@keyframes animateGrow2 {
	0% {opacity:0; translate:calc(-3% - 15px), calc(-100% - 14px); scale:0%;};
	25% {opacity: var(--HotspotHoverOpacity); translate:calc(-3% - 15px), calc(-100% - 14px); scale:0%}
	100% {opacity: var(--HotspotHoverOpacity); translate:calc(-3% - 15px), calc(-100% - 14px); scale:100%}
}

@keyframes animateExit {
0% {opacity: var(--HotspotHoverOpacity); scale:100%}
75% {opacity: var(--HotspotHoverOpacity); scale:0%}
100% {opacity:0; scale:0%;};
}